*{
  font-family: 'League Spartan';
}

.custom-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .slider {
    width: 80%;
  }
  body {
    background-color: #100416;
    margin: 0;
    font-family: 'League Spartan';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .walletbtn{
    /* background-color: #F2DDFF !important; */
    background-image: linear-gradient(to right ,#C180FF,#F2DDFF, #C180FF) !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
  }
  .walletbtn-sm{
    background-image: linear-gradient(to right ,#C180FF,#F2DDFF, #C180FF) !important;
    text-transform: uppercase !important;
  }
  .btnAudit{
    padding-left: 20px!important;
    padding-right: 20px!important;
    color: #2E2006!important;
    font-weight: 600!important;
  }

  @media (max-width: 600px){
      .walletbtn{
                font-size: "small!important";
                margin-left: "0px!important"
              }
              
  .walletbtn-sm{
    font-size: 12px !important;
  }

  }